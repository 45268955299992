import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { useContext } from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { breakpoints, colors, homeLayout } from '../theme'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { ModalContext } from '../components/context/ModalContext'
import PolygonBanner, { StyledPolygonBanner } from '../components/PolygonBanner'
import { ParallaxProvider } from 'react-scroll-parallax'
import ParallaxShape, { StyledParallaxShape } from '../components/ParallaxShape'
import TagsList, { StyledTagsList } from '../components/TagsList'
import People from '../assets/new-home/people.svg'
import { Button, StyledButton } from '@monbanquet/crumble'
import TileList, { StyledTileList } from '../components/TileList'
import Banner from '../components/banners/Banner'
import DoubleBanner from '../components/DoubleBanner'
import EventCard from '../components/EventCard'
import GoogleRatingContainer from '../components/GoogleRatingContainer'
import BottomBanner from '../components/banners/BannerBottom'

const CaseStudyPage = ({ data: { page, homePage } }) => {
  const { dispatch: dispatchModal } = useContext(ModalContext)

  // https://react-slick.neostack.com/docs/api#settings
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2.999,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    swipeToSlide: true,
    draggable: false,
    mobileFirst: true,
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: breakpoints.desktop + 1,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: breakpoints.tablet + 1,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: breakpoints.mobile + 1,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  }

  const buttonText = `Organisez votre ${
    page && page.type ? page.type.toLowerCase() : 'événement'
  }`

  return (
    <StyledCaseStudyPage>
      <Helmet
        title={page.seoTitle}
        meta={[
          { name: 'description', content: page.seoDescription },
          { name: 'keywords', content: page.seoKeywords },
        ]}
      />
      <Navbar />
      <ParallaxProvider>
        <div className="page-content">
          <PolygonBanner picture={page.pictures[0]} color="#F5F5F5">
            <ParallaxShape
              x={5}
              y={5}
              shapeStyle={{
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                border: '10px solid #0F7762',
              }}
            />
            <ParallaxShape
              x={75}
              y={5}
              shapeStyle={{
                height: '24px',
                width: '24px',
                borderRadius: '50%',
                border: '12px solid #0F7762',
              }}
            />
            <ParallaxShape
              x={55}
              y={45}
              shapeStyle={{
                width: '35px',
                height: '19px',
                border: '10px solid #030101',
                borderBottom: 'none',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
                transform: 'matrix(-0.87, 0.48, -0.48, -0.87, 0, 0)',
              }}
            />
            <ParallaxShape
              x={50}
              y={-10}
              shapeStyle={{
                width: '26px',
                height: '33px',
                top: '30%',
                left: '-40%',
                background: '#BC8046 0% 0% no-repeat padding-box',
                clipPath: 'polygon(0% 0%, 25% 0%, 100% 100%, 75% 100%)',
              }}
            />
            <h1>{page.name}</h1>
            <h3>{page.type}</h3>
            <div className="number-of-people">
              <img src={People} alt="people" />
              <p>{page.numberOfPeople} personnes</p>
            </div>
            <div className="banner-action">
              <Button
                onClick={() =>
                  dispatchModal({
                    type: 'OPEN',
                    analytics: { label: 'From_CtaBannerTop' },
                  })
                }
              >
                {buttonText}
              </Button>
            </div>
          </PolygonBanner>

          <div className="case-study-container">
            <div>
              <div>
                <TagsList title="Occasion" tags={page.tagsOccasions} />
                <TagsList title="Services" tags={page.tagsServices} />
              </div>
              <div>
                <p className="description">{page.description.description}</p>
              </div>
            </div>
            <div className="picture-grid-container">
              <ul className="picture-grid">
                {page.pictures.map((pic, i) => (
                  <li key={i}>
                    <div className="picture">
                      <Img
                        fluid={pic.fluid}
                        alt={pic.title}
                        style={imgStyle}
                        loading="lazy"
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="case-study-bottom">
            <div className="banner1-container">
              <ParallaxShape
                x={80}
                y={-45}
                shapeStyle={{
                  width: '437px',
                  height: '437px',
                  borderRadius: '50%',
                  border: '145px solid ' + colors.brand.beige,
                  opacity: '0.5',
                }}
              />
              <ParallaxShape
                x={2}
                y={60}
                shapeStyle={{
                  width: '200px',
                  height: '400px',
                  border: '140px solid ' + colors.brand.beige,
                  borderLeft: 'none',
                  borderBottomRightRadius: '800px',
                  borderTopRightRadius: '800px',
                  transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                  opacity: '0.5',
                }}
              />
              <Banner {...homePage.banner1} />
            </div>
            <div className="events-container">
              <ParallaxShape
                x={80}
                y={-5}
                shapeStyle={{
                  width: '400px',
                  height: '200px',
                  border: '140px solid ' + colors.brand.beige,
                  borderBottom: 'none',
                  borderTopLeftRadius: '400px',
                  borderTopRightRadius: '400px',
                  transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                  opacity: '0.5',
                }}
              />
              <ParallaxShape
                x={10}
                y={25}
                shapeStyle={{
                  width: '400px',
                  height: '400px',
                  border: '140px solid ' + colors.brand.beige,
                  borderRadius: '50%',
                  opacity: '0.5',
                }}
              />
              <TileList
                settings={{
                  ...settings,
                  rows: 2,
                  arrows: false,
                  responsive: [
                    {
                      breakpoint: breakpoints.desktop + 1,
                      settings: {
                        rows: 1,
                        slidesToShow: 2,
                        arrows: true,
                      },
                    },
                    {
                      breakpoint: breakpoints.mobile + 1,
                      settings: {
                        rows: 1,
                        slidesToShow: 1,
                        arrows: false,
                      },
                    },
                  ],
                }}
                centered={true}
                title={homePage.eventShowcaseList.title}
                subTitle={homePage.eventShowcaseList.subText}
                tiles={homePage.eventShowcaseList.events}
                renderTile={<EventCard />}
              />
            </div>
            <GoogleRatingContainer />
            <DoubleBanner left={homePage.banner2} right={homePage.banner3} />
            <BottomBanner bottomBanner={homePage.bottomCta} />
          </div>
        </div>
        <Footer />
      </ParallaxProvider>
    </StyledCaseStudyPage>
  )
}

const imgStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const StyledCaseStudyPage = styled.div`
  overflow-x: hidden;

  ${StyledPolygonBanner} {
    h1 {
      font-size: 60px;
      font-weight: 500;
      line-height: 3.8rem;
      height: 70px;
      color: ${colors.brand.black};
    }

    h3 {
      font-size: 50px;
      font-weight: normal;
      color: ${colors.brand.normal};
    }

    .number-of-people {
      margin: 12px 0 30px;
      display: flex;

      p {
        font-size: 19px;
        margin-left: 15px;
      }
    }

    .banner-action {
      display: flex;

      button {
        z-index: 1;
      }
    }

    @media (max-width: ${breakpoints.desktop}px) {
      .children-content {
        text-align: center;

        h1 {
          font-size: 35px;
          line-height: 40px;
        }

        h3 {
          font-size: 25px;
          font-weight: normal;
        }

        .number-of-people {
          justify-content: center;
        }

        ${StyledButton} {
          margin-top: 15px;
          padding: 0 26px;
        }
      }
    }
  }

  ${StyledButton} {
    margin-top: 30px;
    border-radius: 100px;
    background-color: ${colors.secondary.yellow};
    color: ${colors.brand.black};
    height: 66px;
    font-size: 19px;
    padding: 22px 64px;
    text-transform: none;
  }

  ${StyledTagsList}:last-child {
    margin-top: 40px;
  }

  .description {
    max-width: 650px;
    text-align: left;
    font-size: 18px;
  }

  .picture-grid {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 20px;

    width: 100%;
    margin: auto;
    margin-top: 80px;
    text-align: center;

    li {
      position: relative;

      @media (min-width: ${breakpoints.desktop}px) {
        height: 450px;
        width: 100%;
      }

      @media (min-width: 1366px) {
        height: 350px;
        width: 100%;
      }

      @media (max-width: ${breakpoints.desktop}px) {
        height: 250px;
        width: 100%;
      }

      @media (max-width: ${breakpoints.mobile}px) {
        height: 150px;
        width: 100%;
      }

      .picture {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }

    @media (max-width: ${breakpoints.mobile}px) {
      grid-template-columns: repeat(2, auto);
    }
  }

  .case-study-container {
    width: ${homeLayout.width};
    margin: auto;
    margin-top: 100px;

    > div:first-child {
      display: flex;

      > div {
        width: 50%;
        padding-right: 150px;
      }
    }
  }

  .case-study-bottom {
    background-color: ${colors.background.light};
  }

  .banner1-container {
    position: relative;
    margin-top: 185px;
  }

  ${StyledTileList} {
    position: relative;
    width: ${homeLayout.width};
    margin: auto;
    margin-top: 100px;
  }

  .events-container {
    position: relative;
    margin-top: 171px;
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .banner1-container
      ${StyledParallaxShape},
      .events-container
      ${StyledParallaxShape} {
      display: none;
    }

    .case-study-container {
      width: ${homeLayout.width};
      margin: auto;
      margin-top: 60px;

      > div:first-child {
        display: flex;
        flex-direction: column;

        > div {
          width: 100%;
          padding-right: 0;

          &:last-child {
            margin-top: 30px;
          }
        }
      }
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    page: contentfulEventShowcase(slug: { eq: $slug }) {
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      name
      type
      numberOfPeople
      description {
        description
      }
      menu {
        menu
      }
      pictures {
        title
        fluid(maxHeight: 500, maxWidth: 800, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      location
      priceRange
      tagsOccasions {
        ...TagFragment
      }
      tagsServices {
        ...TagFragment
      }
    }
    homePage: contentfulHomePage(fixedId: { eq: "homepage-1" }) {
      banner1 {
        ...BannerFragment
      }
      eventShowcaseList {
        title
        subText
        events {
          ...EventShowcaseFragment
        }
      }
      banner2 {
        ...BannerFragment
      }
      banner3 {
        ...BannerFragment
      }
      bottomCta {
        ...CallToActionFragment
      }
    }
  }
`

export default CaseStudyPage
